import React, { useState } from 'react';
import { getRequest, postRequest } from './UrlRequest';
import './Modal.css';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

const Hospital = () => {
  const [searchParams, setSearchParams] = useState({ name: '', address: '' });
  const [hospitalList, setHospitalList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('accessToken');
      await postRequest('dialysis', '/logout', {}, token);
//      console.log('Logged out successfully.');
    } catch (error) {
      //console.error('Error logging out:', error);
    } finally {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      navigate('/login');
    }
  };

  // 병원 검색 (GET 요청)
  const searchHospitals = async () => {
    try {
      const token = localStorage.getItem('accessToken');
      const endpoint = `/hospitals/search?name=${searchParams.name}&address=${searchParams.address}`;
      const data = await getRequest('dialysis', endpoint, token);
      setHospitalList(data.hospitals);
    } catch (error) {
      //console.error('Error fetching hospitals:', error);
    }
  };

  // 병원 등록/수정 (POST 요청)
  const registerHospital = async (hospitalId) => {
    try {
      const token = localStorage.getItem('accessToken');
      const endpoint = '/user/hospital';
      const requestBody = { hospital_id: hospitalId };
      await postRequest('dialysis', endpoint, requestBody, token);
      alert('병원이 성공적으로 등록/수정되었습니다.');
    } catch (error) {
      //console.error('Error registering hospital:', error);
      alert('병원 등록/수정에 실패했습니다.');
    }
  };

  // 검색 필드 업데이트
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchParams((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div>
        {/* 병원 등록/수정 버튼 주석 처리
      <button
        onClick={() => setIsModalOpen(true)}
                  style={{
            padding: '10px',
            backgroundColor: '#333',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
      >
        병원 검색/등록
    </button>
    */}
      <button onClick={handleLogout} className="logout-button" style={{
            padding: '10px',
            backgroundColor: '#333',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }} >
        로그아웃
      </button>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={{
          overlay: { backgroundColor: '#333' },
          content: {
            width: '50%',
            margin: 'auto',
            borderRadius: '10px',
            padding: '20px',
          },
        }}
      >
        <h1>병원 검색 및 등록</h1>
        <div style={{ marginBottom: '20px' }}>
          <label style={{ display: 'block', marginBottom: '10px' }}>
            병원 이름:
            <input
              type="text"
              name="name"
              value={searchParams.name}
              onChange={handleInputChange}
              style={{
                width: '100%',
                padding: '10px',
                margin: '10px 0',
                borderRadius: '5px',
                border: '1px solid #ccc',
              }}
            />
          </label>
          <label style={{ display: 'block', marginBottom: '10px' }}>
            병원 주소:
            <input
              type="text"
              name="address"
              value={searchParams.address}
              onChange={handleInputChange}
              style={{
                width: '100%',
                padding: '10px',
                margin: '10px 0',
                borderRadius: '5px',
                border: '1px solid #ccc',
              }}
            />
          </label>
          <button
            onClick={searchHospitals}
            style={{
              padding: '10px',
              backgroundColor: '#333',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            검색
          </button>
          <button
          onClick={() => setIsModalOpen(false)}
          style={{
            padding: '10px',
            backgroundColor: '#333',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          닫기
        </button>
        </div>

        <div style={{ marginBottom: '20px' }}>
          <h2>검색 결과</h2>
          <ul style={{ listStyleType: 'none', padding: '0' }}>
  {hospitalList.map((hospital) => (
    <li
      key={hospital.id}
      onClick={() => registerHospital(hospital.id)}
      style={{
        padding: '10px',
        marginBottom: '5px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer', // 클릭 가능한 스타일
        backgroundColor: '#f8f9fa', // 클릭 영역 강조를 위해 배경색 추가
      }}
    >
      <span>
        {hospital.name} - {hospital.address}
      </span>
    </li>
  ))}
</ul>

        </div>

      </Modal>
    </div>
  );
};

export default Hospital;
