// Refresh.js
import axios from 'axios';
import { loginRequest } from './UrlRequest';

let alertShown = false; // 경고 메시지 중복 방지
let isRefreshing = false; // 중복 토큰 갱신 방지
let pendingRequests = []; // 대기 중인 요청을 저장하는 배열

export const refreshToken = async () => {
  try {
    const refreshEndpoint = '/api/v1/refresh';
    const tokenData = {
      accessToken: localStorage.getItem('accessToken'),
      refreshToken: localStorage.getItem('refreshToken'),
    };

    const response = await loginRequest(refreshEndpoint, tokenData);

    if (response) {
      localStorage.setItem('accessToken', response.accessToken);
      localStorage.setItem('refreshToken', response.refreshToken);
      //console.log('토큰 재발급 완료:', response.accessToken);

      // 대기 중인 요청들을 처리하고 플래그 초기화
      processPendingRequests(response.accessToken);
      alertShown = false; // 경고 플래그 초기화
      return { accessToken: response.accessToken, refreshToken: response.refreshToken };
    } else {
      //console.warn('토큰 재발급 실패: 재로그인 필요');
      if (!alertShown) {
        alert('토큰이 만료되었습니다. 재로그인 후 이용해주세요.');
        alertShown = true;
      }
      processPendingRequests(null); // 모든 대기 중인 요청을 실패로 처리
      return null;
    }
  } catch (error) {
    //console.error('토큰 재발급 요청 에러:', error);
    processPendingRequests(null);
    return null;
  } finally {
    isRefreshing = false; // 갱신 작업 완료
  }
};

export const handleTokenExpiry = async (originalRequest) => {
  if (!isRefreshing) {
    isRefreshing = true;
    const tokens = await refreshToken();
    if (tokens && tokens.accessToken) {
      originalRequest.headers['Authorization'] = `Bearer ${tokens.accessToken}`;

      try {
        const retryResponse = await axios(originalRequest); // 갱신된 토큰으로 요청 재시도
        //console.log('재시도 성공:', retryResponse);
        return retryResponse;
      } catch (retryError) {
        //console.error('재시도 요청 에러:', retryError);
        throw retryError;
      }
    } else {
      //console.warn('재로그인이 필요합니다.');
      return Promise.reject('토큰 만료로 인한 요청 실패');
    }
  } else {
    // 갱신 중인 경우 대기열에 요청 추가
    return new Promise((resolve, reject) => {
      pendingRequests.push((newToken) => {
        if (newToken) {
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          resolve(axios(originalRequest));
        } else {
          reject('토큰 만료로 인한 요청 실패');
        }
      });
    });
  }
};

const processPendingRequests = (newToken) => {
  pendingRequests.forEach((callback) => callback(newToken));
  pendingRequests = []; // 대기열 초기화
};
