import React, { useState } from 'react';
import Diet from './Diet';
import Health from './Health';
//import Hospital from './Hospital';
const Tabs = ({ patientId, selectedDate, deviceStatusId }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isListView, setIsListView] = useState(true); // 리스트와 폼 전환 상태

  // 리스트와 폼 화면 전환 함수
  const toggleView = () => {
    setIsListView(!isListView);
  };

  const tabs = [
    // `onDate` prop에 `selectedDate` 전달
  //  { label: '병원 등록/수정', component: <Hospital /> },
    { label: '식단', component: <Diet patientId={patientId} selectedDate={selectedDate} /> },
    { label: '목표와 피드백', component: <Health patientId={patientId} onDate={selectedDate} /> },

  ];

  return (
    <div className="tab-container" style={{ height: '100%', overflowY: 'hidden'}}>
      <div className="tab-buttons">
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => setActiveTab(index)}
            className={activeTab === index ? 'active-tab' : ''}
          >
            {tab.label}
          </button>
        ))}

      </div>
      <div className="tab-content" style={{ height: '450px'}}>
        {tabs[activeTab].component}
      </div>
    </div>
  );
};

export default Tabs;
