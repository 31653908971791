// KakaoCallback.js
import React, { useState, useEffect } from 'react';
import { loginRequest, postRequest, ssoRequest } from './UrlRequest';
import { useNavigate } from 'react-router-dom';
import SignupModal from './SignupModal';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const KakaoCallback = () => {
  const [userInfo, setUserInfo] = useState({});
  const [isSignupRequired, setIsSignupRequired] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) sendCodeToBackend(code);
  }, []);

  const sendCodeToBackend = async (code) => {
    try {
      const response = await ssoRequest('/api/v1/login/kakao', {
        application: 'dialysis',
        'authorization-code': code,
        'redirect-uri': 'https://doctor-dm.livincare.kr/kakao',
      });

      //console.log('Full response data:', response); // 전체 데이터 확인

          // userInfo가 포함된 위치에서 필요한 필드를 추출

    const fetchedUserInfo = response.userInfo || {};
    const accessToken = response.accessToken || '';
    const refreshToken = response.refreshToken || '';

    const account = fetchedUserInfo.account || '';
    const nickname = fetchedUserInfo.nickname || '';
    const password = fetchedUserInfo.password || '';

      if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        fetchUserData();
      } else {
        //console.log('Account:', account); // 확인용
        //console.log('Nickname:', nickname); // 확인용
        //console.log('Password:', password); // 확인용
        setUserInfo(fetchedUserInfo);  // 회원가입에 필요한 정보를 설정
        setIsSignupRequired(true);  // 모달을 열어 회원가입을 진행하도록 설정
      }
    } catch (error) {
      //console.error('Error during login:', error);
    }
  };

  const fetchUserData = async () => {
    try {
      navigate('/dashboard');
    } catch (error) {
      //console.error('Error fetching user data:', error);
    }
  };

  return (
    <div>
      <h2>로그인 중입니다...</h2>
      {isSignupRequired && (
        <SignupModal
          isOpen={isSignupRequired}
          onRequestClose={() => setIsSignupRequired(false)}
          userInfo={userInfo}
        />
      )}
    </div>
  );
};

export default KakaoCallback;
