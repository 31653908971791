// DataChart.js
import React, { useState, useEffect } from 'react';
import { getRequest } from '../UrlRequest';
import Chart from './Chart';
import { useLocation } from 'react-router-dom';

const DataChart = ({
  patientId,
  dataType,
  endpoint,
  label,
  multipleLines,
  selectedDate,
  selectedPeriod,
}) => {
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartData2, setChartData2] = useState([]);
  const [chartDummy, setChartDummy] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const application = location.state?.application || 'dialysis';

  const fetchData = async () => {
    if (!patientId || !endpoint) return;

    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('accessToken');
      const response = await getRequest(
        application,
        `${endpoint}?on-date=${selectedDate}&period=${selectedPeriod}`,
        token
      );

      if (response) {
        setChartLabels(response.dates || []);
        setChartData(response.systolics || response.heart_rates || response.avg_weights || []);
        setChartData2(response.diastolics || []);
        setChartDummy(response.is_dummy || []);
      } else {
        setError('데이터를 불러오는 중 오류가 발생했습니다.');
      }
    } catch (error) {
      setError(`데이터를 불러오는 중 오류가 발생했습니다: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [application, patientId, selectedDate, selectedPeriod]);

  if (loading) return <p>로딩 중...</p>;
  if (error) return <p>{error}</p>;

  if (!chartLabels.length || !chartData.length) {
    return <p>선택한 기간에 대한 데이터가 없습니다.</p>;
  }

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Chart
        labels={chartLabels}
        data={chartData}
        data2={multipleLines ? chartData2 : null}
        label={label}
        label2={multipleLines ? '이완기' : null}
        dummyData={chartDummy}
      />
    </div>
  );
};

export default DataChart;
