import React, { useEffect, useState } from 'react';
import { getRequest } from '../UrlRequest';
import DataChart from './DataChart';
import { useLocation } from 'react-router-dom';

const Weight = ({ patientId, selectedDate, selectedPeriod }) => {
  const [weightData, setWeightData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const application = location.state?.application || 'dialysis';

  useEffect(() => {
    const fetchWeightData = async () => {
      if (!patientId || !selectedDate) return;

      setLoading(true);
      setError(null);
      setWeightData(null); // 날짜 변경 시 기존 데이터 초기화

      try {
        const token = localStorage.getItem('accessToken');

        // 기간 내 평균 체중 데이터를 먼저 가져옴
        const periodResponse = await getRequest(application,
          `/doctor/${patientId}/weight/period?on-date=${selectedDate}&period=${selectedPeriod}`,
          token
        );

        // am_weight와 pm_weight 데이터 가져옴
        const dailyResponse = await getRequest(application,
          `/doctor/${patientId}/weight/daily?on-date=${selectedDate}`,
          token
        );

        // am_weight 또는 pm_weight 중 하나라도 있으면 dailyResponse 사용
        if (dailyResponse && (dailyResponse.am_weight || dailyResponse.pm_weight || dailyResponse.dry_weight)) {
          setWeightData({ 
            am_weight: dailyResponse.am_weight || `${selectedDate}에 대한 아침 체중 데이터가 없습니다.`, 
            pm_weight: dailyResponse.pm_weight || `${selectedDate}에 대한 저녁 체중 데이터가 없습니다.`,
            dry_weight: dailyResponse.dry_weight || `${selectedDate}에 대한 기준 체중 데이터가 없습니다.`,
            avg_weight: periodResponse?.avg_weights || []
          });
        } else if (periodResponse && periodResponse.avg_weights?.length > 0) {
          // 일별 데이터가 없으나 기간 내 평균 체중 데이터가 있을 경우
          setWeightData({ 
            am_weight: `${selectedDate}에 대한 아침 체중 데이터가 없습니다.`, 
            pm_weight: `${selectedDate}에 대한 저녁 체중 데이터가 없습니다.`,
            dry_weight: `${selectedDate}에 대한 기준 체중 데이터가 없습니다.`,
            avg_weight: periodResponse.avg_weights 
          });
        } else {
          setError('해당 날짜와 기간에 체중 데이터가 없습니다.');
        }
      } catch (error) {
        setError('');
      } finally {
        setLoading(false);
      }
    };

    fetchWeightData();
  }, [application, patientId, selectedDate, selectedPeriod]);

  if (loading) return <p><strong>환자의 데이터를 클릭하면 로드 합니다.</strong></p>;

  return (
    <div className="chartcontainer">
      <h3>체중(kg)</h3>
      {weightData ? (
        <div style={{ marginBottom: '20px' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '10px' }}>
            <span style={{ fontSize: '14px', color: '#666', flex: '1', display: 'flex', gap: '20px' }}>
              <div style={{ minWidth: '120px' }}>
                아침: <strong style={{ color: '#2196f3' }}>{typeof weightData.am_weight === 'number' ? `${weightData.am_weight} kg` : weightData.am_weight}</strong>
              </div>
              <div style={{ minWidth: '120px' }}>
                저녁: <strong style={{ color: '#2196f3' }}>{typeof weightData.pm_weight === 'number' ? `${weightData.pm_weight} kg` : weightData.pm_weight}</strong>
              </div>
            </span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <span style={{ fontSize: '14px', color: '#666', flex: '1', display: 'flex', gap: '20px' }}>
              {weightData.avg_weight?.length > 0 && (
                <div style={{ minWidth: '120px' }}>
                  평균: <strong style={{ color: '#2196f3' }}>{weightData.avg_weight[weightData.avg_weight.length - 1]} kg</strong>
                </div>
              )}
              <div style={{ minWidth: '120px' }}>
                건체중: <strong style={{ color: '#2196f3' }}>{typeof weightData.dry_weight === 'number' ? `${weightData.dry_weight} kg` : weightData.dry_weight}</strong>
              </div>
            </span>
          </div>
        </div>
      ) : (
        <p>{selectedDate}에 대한 체중 데이터가 없습니다.</p>
      )}
      <DataChart
        patientId={patientId}
        dataType="weight"
        endpoint={`/doctor/${patientId}/weight/period`}
        label="평균 체중"
        selectedDate={selectedDate}
        selectedPeriod={selectedPeriod}
        chartLabels={weightData?.avg_weight?.dates || []}
        chartData={weightData?.avg_weight?.values || []}
        chartDummy={weightData?.avg_weight?.is_dummy || []}
      />
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default Weight;
