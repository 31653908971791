import React, { useEffect, useState } from 'react';
import { getRequest } from '../UrlRequest';

const Health = ({ patientId, onDate, application = 'dialysis' }) => {
  const [feedback, setFeedback] = useState(null);
  const [goals, setGoals] = useState([]);
  const [loadingFeedback, setLoadingFeedback] = useState(true);
  const [loadingGoals, setLoadingGoals] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!patientId || !onDate) return;
    fetchFeedback();
    fetchGoals();
  }, [patientId, onDate, application]);

  

 const fetchFeedback = async () => {
  setLoadingFeedback(true);
  setError(null);
  try {
    const token = localStorage.getItem('accessToken');
    
    // onDate에 1일 더하기
    const modifiedDate = new Date(onDate);
    modifiedDate.setDate(modifiedDate.getDate() + 1);
    const formattedDate = modifiedDate.toISOString().split('T')[0]; // YYYY-MM-DD 형식으로 변환

    const data = await getRequest(application, `/doctor/${patientId}/feedback/daily?on-date=${formattedDate}`, token);
    setFeedback(data);
  } catch (error) {
    setError('피드백 데이터가 없습니다.');
  } finally {
    setLoadingFeedback(false);
  }
};

const fetchGoals = async () => {
  setLoadingGoals(true);
  setError(null);
  try {
    const token = localStorage.getItem('accessToken');
    
    // onDate에 1일 더하기
    const modifiedDate = new Date(onDate);
    modifiedDate.setDate(modifiedDate.getDate() + 1);
    const formattedDate = modifiedDate.toISOString().split('T')[0]; // YYYY-MM-DD 형식으로 변환

    const data = await getRequest(application, `/doctor/${patientId}/goal/daily?on-date=${formattedDate}`, token);
    setGoals(data);
  } catch (error) {
    setError('목표 데이터가 없습니다.');
  } finally {
    setLoadingGoals(false);
  }
};
// ... existing imports ...

// 컴포넌트 위에 스타일 추가
const styles = {
  healthContainer: {
    padding: '20px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    margin: '15px'
  },
  section: {
    backgroundColor: '#ffffff',
    padding: '15px',
    borderRadius: '6px',
    marginBottom: '20px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
  },
  sectionTitle: {
    color: '#333',
    borderBottom: '2px solid #e0e0e0',
    paddingBottom: '10px',
    marginBottom: '15px'
  },
  date: {
    color: '#666',
    fontSize: '1.2em',
    marginBottom: '15px'
  },
  goalItem: {
    padding: '15px',
    borderLeft: '3px solid #4CAF50',
    marginBottom: '15px',
    backgroundColor: '#f9f9f9'
  },
  feedbackContent: {
    padding: '15px',
    backgroundColor: '#f0f7ff',
    borderRadius: '4px'
  },
  loadingText: {
    color: '#666',
    fontStyle: 'italic'
  },
  errorText: {
    color: '#f44336',
    padding: '10px'
  }
};

// return 문 내부의 className들을 style로 변경
return (
  <div style={styles.healthContainer}>
    <div style={styles.section}>
      <h1 style={styles.date}>{onDate}</h1>
      <h3 style={styles.sectionTitle}>피드백</h3>

      {loadingFeedback ? (
        <p style={styles.loadingText}>피드백을 불러오는 중...</p>
      ) : error ? (
        <p style={styles.errorText}>{error}</p>
      ) : feedback ? (
        <div style={styles.feedbackContent}>
          <p><strong>요약:</strong> {feedback.summary}</p>
          <p><strong>기간:</strong> {feedback.period}일</p>
        </div>
      ) : (
        <p>피드백 데이터가 없습니다.</p>
      )}
    </div>

    <div style={styles.section}>
      <h3 style={styles.sectionTitle}>목표</h3>
      {loadingGoals ? (
        <p style={styles.loadingText}>목표를 불러오는 중...</p>
      ) : error ? (
        <p style={styles.errorText}>{error}</p>
      ) : goals.length > 0 ? (
        goals.map((goal) => (
          <div key={goal.id} style={styles.goalItem}>
            <p><strong>태그:</strong> {goal.tag}</p>
            <p><strong>내용:</strong> {goal.content}</p>
            <p><strong>사유:</strong> {goal.reason}</p>
            <p><strong>상태:</strong> {goal.status ? '완료' : '미완료'}</p>
            <p><strong>기간:</strong> {goal.period}일</p>
          </div>
        ))
      ) : (
        <p>목표 데이터가 없습니다.</p>
      )}
    </div>
  </div>
);
};

export default Health;
